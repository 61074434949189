<script setup lang="ts">
import { useAppStore } from "~/stores/app";

const store = useAppStore();
</script>

<template>
  <div class="p-3 w-full h-14 bg-white border-b border-slate-300 flex justify-between">
    <a href="/"><img :src="store?.productLogo" alt="logo" /></a>
    <div class="text-base text-slate-900 font-medium self-center">
      {{ store.pageTitle }}
    </div>
    <div class="flex gap-1">
      <select-locale />
      <button-user-avatar />
    </div>
  </div>
</template>
